import { useEffect }             from "react";
import { useState }              from "react";
import { useMemo }               from "react";
import { useThrottle }           from "@relcu/ui";
import { transformNameToLabel }  from "@relcu/ui";
import { getSelectionSet }       from "../../../../utils/graphQlUtils";
import { toFirstLower }          from "../../../../utils/helpers";
import { pluralize }             from "../../../../utils/pluralize";
import { useJqlQuery }           from "../../Jql";
import { PointerListFieldProps } from "./PointerListField";

export function usePointerListField({ targetClass, fields, filters, view, ids }: Partial<PointerListFieldProps>) {
  const selectionSet = useMemo(() => getSelectionSet(fields), [fields]);
  const [selectedIds, setSelectedIds] = useState(ids ?? []);
  const [q, setQ] = useState("");
  const [search, setSearch] = useThrottle(q, 1000);
  useEffect(() => setSearch(q), [q]);
  const targetClasses = Array.isArray(targetClass) ? targetClass : [targetClass];
  const query = useMemo(() => {
    let query: any;
    let value = {
      ...filters
    };
    const variables = {
      after: "",
      first: 5
    };

    if (search) {
      variables[ "search" ] = `^${(search || "").trim()}`;
    }

    if (targetClasses.length > 1) {
      query = targetClasses.map(n => {
        const operation = pluralize(toFirstLower(n));
        return {
          operation,
          variables: {
            ...variables,
            [ `${operation}Where` ]: {
              type: `${n}WhereInput`,
              name: "where",
              value
            }
          },
          fields: [
            {
              pageInfo: [
                "endCursor",
                "startCursor",
                "hasNextPage",
                "hasPreviousPage"
              ]
            },
            {
              edges: [
                {
                  node: selectionSet
                }
              ]
            }
          ]
        };
      });
    } else {
      variables[ "first" ] = 10;
      variables[ "where" ] = {
        type: `${targetClasses[ 0 ]}WhereInput`,
        name: "where",
        value
      };
      query = [
        {
          operation: pluralize(toFirstLower(targetClasses[ 0 ])),
          variables,
          fields: [
            {
              pageInfo: [
                "endCursor",
                "startCursor",
                "hasNextPage",
                "hasPreviousPage"
              ]
            },
            {
              edges: [
                {
                  node: selectionSet
                }
              ]
            }
          ]
        }
      ];

      if (selectedIds.length) {
        query[ 0 ].variables.where.value[ "objectId" ] = {
          notIn: selectedIds
        };
        query.push({
          operation: `selected${pluralize(targetClasses[ 0 ])}:${pluralize(toFirstLower(targetClasses[ 0 ]))}`,
          variables: {
            after: "",
            first: 10,
            selectedWhere: {
              type: `${targetClasses[ 0 ]}WhereInput`,
              name: "where",
              value: {
                objectId: {
                  in: selectedIds
                }
              }
            }
          },
          fields: [
            {
              pageInfo: [
                "endCursor",
                "startCursor",
                "hasNextPage",
                "hasPreviousPage"
              ]
            },
            {
              edges: [
                {
                  node: selectionSet
                }
              ]
            }
          ]
        });
      }
    }
    return query;
  }, [search, selectedIds, targetClasses]);

  const { data = {}, loading, fetchMore } = useJqlQuery(query, {
    operationName: `${targetClasses.join("And")}ListQuery`,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: view === "read"
  });

  const options = useMemo(() => {
      if (targetClasses.length > 1) {
        const items = [];
        Object.keys(data).forEach(t => {
          const label = t == "leadSources" ? "CAMPAIGN" : transformNameToLabel(t);
          const options = data[ t ].edges.map(({ node }) => node);
          if (options.length) {
            items.push({
              label,
              options: data[ t ].edges.map(({ node }) => node)
            });
          }
        });
        return items;
      }

      const operation = pluralize(toFirstLower(targetClasses[ 0 ]));
      let result = [];

      if (selectedIds.length) {
        const selectedOperation = `selected${pluralize(targetClasses[ 0 ])}`;
        if (data[ selectedOperation ]) {
          result = [...(Object(data[ selectedOperation ])[ "edges" ] || []).map(({ node }) => node)];
        }
      }

      if (data[ operation ]) {
        (Object(data[ operation ])[ "edges" ] || []).forEach(({ node }) => result.push(node));
      }

      return result;
    },
    [data, targetClasses, selectedIds]
  );
  const pageInfo = useMemo(() => {
    const operation = pluralize(toFirstLower(targetClasses[ 0 ]));
    return Object(data[ operation ])[ "pageInfo" ];
  }, [data, targetClasses]);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        where: query.variables.where.value,
        first: query.variables.first,
        after: pageInfo.endCursor
      },
      updateQuery(prev, { fetchMoreResult }) {
        const className = pluralize(toFirstLower(targetClasses[ 0 ]));
        return {
          [ className ]: {
            ...prev[ className ],
            pageInfo: fetchMoreResult[ className ].pageInfo,
            edges: [...prev[ className ].edges, ...fetchMoreResult[ className ].edges]
          }
        };
      }
    });
  };

  return {
    handleLoadMore,
    setSelectedIds,
    pageInfo,
    options,
    loading,
    setQ,
    q
  };
}
