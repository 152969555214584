import { useMutation }                      from "@apollo/client";
import { useApolloClient }                  from "@apollo/client";
import { useQuery }                         from "@apollo/client";
import { useFragment }                      from "@apollo/client";
import { ReactiveVar }                      from "@apollo/client/cache/inmemory/reactiveVars";
import { useModal }                         from "@relcu/ui";
import { Column }                           from "rsuite-table";
import { Cell }                             from "rsuite-table";
import { HeaderCell }                       from "rsuite-table";
import { Table }                            from "rsuite-table";
import { EmptyState }                       from "@relcu/rc";
import { Header }                           from "@relcu/rc";
import { Container }                        from "@relcu/rc";
import { Toolbar }                          from "@relcu/rc";
import { Icon }                             from "@relcu/rc";
import { Button }                           from "@relcu/rc";
import { Badge }                            from "@relcu/rc";
import { Typography }                       from "@relcu/rc";
import { FlexboxGrid }                      from "@relcu/rc";
import { List }                             from "@relcu/rc";
import { useNavigate }                      from "@relcu/react-router";
import { useParams }                        from "@relcu/react-router";
import { Alignment }                        from "@relcu/ui";
import { Popper }                           from "@relcu/ui";
import { Box }                              from "@relcu/ui";
import { ToggleSwitch }                     from "@relcu/ui";
import { CopyText }                         from "@relcu/ui";
import { useSource }                        from "@relcu/ui";
import { format }                           from "@relcu/ui";
import { DateTime }                         from "luxon";
import { useMemo }                          from "react";
import { useRef }                           from "react";
import { useEffect }                        from "react";
import { useCallback }                      from "react";
import { useState }                         from "react";
import React                                from "react";
import { LoanEstimateOffer }                from "../../../../../graph/__types__/LoanEstimateOffer";
import { LOAN_ESTIMATE_OFFER }              from "../../../../../graph/operations.graphql";
import { toNodeId }                         from "../../../../../utils/helpers";
import { UPDATE_LOAN_ESTIMATE_OFFER }       from "../OfferTable/Offer";
import { GET_PRICING_RATES }                from "../OfferTable/Offer";
import { GetPricingRates_getRates_rates }   from "../OfferTable/Offer/__types__/GetPricingRates";
import { GetPricingRatesVariables }         from "../OfferTable/Offer/__types__/GetPricingRates";
import { GetPricingRates }                  from "../OfferTable/Offer/__types__/GetPricingRates";
import { UpdateLoanEstimateOfferVariables } from "../OfferTable/Offer/__types__/UpdateLoanEstimateOffer";
import { UpdateLoanEstimateOffer }          from "../OfferTable/Offer/__types__/UpdateLoanEstimateOffer";
import { GroupsState }                      from "../PricingBetaView";
import { StateRef }                         from "../PricingBetaView";
import { useAppendObCustomFields }          from "../useDefaultOffer";
import { IneligibleRateDialog }             from "./IneligibleRateDialog";

export const RateTable = React.forwardRef(function RateTable(props: {
  groupsStateVar?: ReactiveVar<GroupsState>
}, ref: React.RefObject<StateRef>) {
  const client = useApolloClient();
  const [modal, contextHolder] = useModal(IneligibleRateDialog);
  const { groupsStateVar } = props;
  const { $object: lead } = useSource();
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();
  const [best, setBest] = useState(null);
  const [expiredChecked, setExpiredChecked] = useState(false);
  const { offerId } = useParams();
  const { data: { getRates: { rates = [], searchId, engine } = {} } = {}, loading } = useQuery<GetPricingRates, GetPricingRatesVariables>(GET_PRICING_RATES, {
    variables: {
      offerId
    }
  });
  const sortingFunction = (r1, r2) => {
    if (r1.rate < r2.rate) {
      return -1;
    }
    if (r2.rate < r1.rate) {
      return 1;
    }
    if (r1.price < r2.price) {
      return 1;
    }
    if (r2.price < r1.price) {
      return -1;
    }
    return 0;
  };

  let filteredRates = useMemo(() => {
    let r = [...(!expiredChecked ? rates.filter(r => r.status !== "Expired") : rates)];
    return r.sort(sortingFunction);
  }, [rates, expiredChecked]);

  const selectedRef = useRef(null);
  const bestRef = useRef(null);

  useEffect(() => {
    if (!filteredRates?.length) {
      return setBest(null);
    }
    const indexedRates = filteredRates.map((r, i) => ({ ...r, index: i }));
    let sortedRates = indexedRates.filter((r) => (r.price >= 100 && r.status == "Available"));
    sortedRates = sortedRates.sort(sortingFunction);
    return setBest(sortedRates[ 0 ]?.index ?? null);
  }, [filteredRates]);

  useEffect(() => {
    if (selectedRef?.current) {
      selectedRef.current.scrollIntoView({ behavior: "auto", block: "nearest", inline: "nearest" });
    } else if (bestRef?.current) {
      bestRef.current.scrollIntoView({ behavior: "auto", block: "nearest", inline: "nearest" });
    }
  }, [rates, bestRef.current, selectedRef.current]);

  const [update] = useMutation<UpdateLoanEstimateOffer, UpdateLoanEstimateOfferVariables>(useAppendObCustomFields(UPDATE_LOAN_ESTIMATE_OFFER));
  const { data: loanEstimateOffer } = useFragment<LoanEstimateOffer>({
    fragment: useAppendObCustomFields(LOAN_ESTIMATE_OFFER),
    fragmentName: "LoanEstimateOffer",
    from: client.cache.identify({
      __typename: "LoanEstimateOffer",
      id: toNodeId({ className: "LoanEstimateOffer", objectId: offerId })
    })
  });
  useEffect(() => {
    if (loanEstimateOffer.rateId) {
      setSelected(loanEstimateOffer.rateId);
    }
  }, [loanEstimateOffer.rateId]);

  const countPrice = useCallback((price, loanAmount) => {
    return format((loanAmount * (price - 100) / 100), 2);
  }, []);

  const onSelect = async (result: GetPricingRates_getRates_rates, index) => {
    await update({
      variables: {
        input: {
          id: loanEstimateOffer.id,
          fields: {
            rate: result.rate,
            apr: result.apr,
            price: result.price,
            pi: result.pi,
            monthlyPremium: result.monthlyPremium,
            rateId: result.rateId,
            rateInvestor: result.vendorName,
            rateUpdated: result.lastUpdate
          }
        }
      }
    });
    setSelected(result.rateId);
    const prev = groupsStateVar();
    groupsStateVar({
      ...prev,
      isLoanCriteriaOpen: true
    });
    ref.current.isRateSelected = true;
    ref.current.offers[ offerId ] = result;
    navigate(`/lead/${lead.objectId}/pricing-beta`);
  };

  const [popperAnchorBounding, setPopperAnchorBounding] = useState(null);
  const [popperActiveIndex, setPopperActiveIndex] = useState(null);
  const handleRateClick = useCallback((e, index) => {
    if (index === popperActiveIndex || (filteredRates[ index ]?.adjustments || []).length === 0) {
      setPopperActiveIndex(null);
      setPopperAnchorBounding(null);
    } else {
      setPopperActiveIndex(index);
      setPopperAnchorBounding(e.currentTarget.getBoundingClientRect());
    }
  }, [setPopperAnchorBounding, popperAnchorBounding, popperActiveIndex, setPopperActiveIndex, filteredRates]);

  return <Container>
    {contextHolder}
    <Header>
      <Toolbar childrenRenderMode={"clone"}>
        <Button size={"xs"} appearance={"text"} startIcon={<Icon type={"arrow_back"}/>}
                onClick={() => navigate(-1)}> BACK
          TO OFFERS </Button>
        <Typography color={"primary"}>Select the rates for {loanEstimateOffer?.objectName}</Typography>
        <Box container style={{ flexGrow: 1 }} gap={"XS"} justify={"end"}>
          {engine == "optimalblue" && <Box container alignItems={"center"} gap={"XXXS"}>
            <Box>
              <Button size={"xs"} appearance={"text"}
                      onClick={() => {
                        modal({ searchId });
                      }}>Ineligible rates</Button>
            </Box>
            <Box container alignItems={"center"} gap={"XXXS"}>
              <Typography>Search ID : </Typography>
              <CopyText value={searchId} ellipsis={true} showCopyByDefault>
                <Typography color={"primary"}> {searchId}</Typography>
              </CopyText>
            </Box>
          </Box>}
          <Box container alignItems={"center"} gap={"XXXS"}>
            <Typography variant={"base14"}>
              Show expired offers
            </Typography>
            <ToggleSwitch onChange={(e) => setExpiredChecked(e)} checked={expiredChecked}/>
          </Box>
        </Box>
      </Toolbar>
    </Header>
    {loading
      ? <EmptyState
        background
        icon={"cached"}
        title={"Getting data from sources..."}
        subtitle={"Please wait until the data is downloaded. Usually, it takes less than a minute"}/>

      : filteredRates.length ?
        <>
          <List style={{ background: "var(--rc-surface-primary)" }}>
            <List.Item index={1} header={true} size={"sm"} style={{ zIndex: 1, position: "sticky", top: 0 }}>
              <FlexboxGrid justify={"space-between"}>
                <FlexboxGrid.Cell colspan={1} justify={"end"}>
                </FlexboxGrid.Cell>
                <FlexboxGrid.Cell colspan={7} justify={"start"}>
                  <Typography color={"tertiary"}>Investor</Typography>
                </FlexboxGrid.Cell>
                <FlexboxGrid.Cell colspan={4} justify={"start"}>
                  <Typography color={"tertiary"}>Product</Typography>
                </FlexboxGrid.Cell>
                <FlexboxGrid.Cell colspan={1} justify={"end"}>
                  <Typography color={"tertiary"}>Rate %</Typography>
                </FlexboxGrid.Cell>
                <FlexboxGrid.Cell colspan={1} justify={"end"}>
                  <Typography color={"tertiary"}>APR %</Typography>
                </FlexboxGrid.Cell>
                <FlexboxGrid.Cell colspan={1} justify={"end"}>
                  <Typography color={"tertiary"}>Price %</Typography>
                </FlexboxGrid.Cell>
                <FlexboxGrid.Cell colspan={2} justify={"end"}>
                  <Typography color={"tertiary"}>Status</Typography>
                </FlexboxGrid.Cell>
                <FlexboxGrid.Cell colspan={2} justify={"end"}>
                  <Typography color={"tertiary"}>Price $</Typography>
                </FlexboxGrid.Cell>
                <FlexboxGrid.Cell colspan={2} justify={"end"}>
                  <Typography color={"tertiary"}>Updated</Typography>
                </FlexboxGrid.Cell>
                <FlexboxGrid.Cell colspan={2}>
                </FlexboxGrid.Cell>
              </FlexboxGrid>
            </List.Item>
            {filteredRates?.map((item, index) => {
              const isBest = best === index;
              const isSelected = selected === item.rateId;
              return (
                <List.Item ref={isSelected ? selectedRef : (isBest ? bestRef : null)} size={"sm"} key={index}
                           selected={isBest} active={isSelected} index={index + 2}
                           onClick={(e) => handleRateClick(e, index)}
                           style={{ cursor: "pointer", backgroundColor: index == popperActiveIndex ? "var(--rs-message-info-bg)" : "" }}>
                  <FlexboxGrid justify={"space-between"}>
                    <FlexboxGrid.Cell colspan={1}>
                      {isBest && <Badge color={"green"} content="PAR"/>}
                    </FlexboxGrid.Cell>
                    <FlexboxGrid.Cell colspan={7} justify={"start"}>
                      <Typography color={"primary"}> {item.vendorName.split("(")[ 0 ]}</Typography>
                    </FlexboxGrid.Cell>
                    <FlexboxGrid.Cell colspan={4} justify={"start"}>
                      <Typography color={"primary"}> {item.productName}</Typography>
                    </FlexboxGrid.Cell>
                    <FlexboxGrid.Cell colspan={1} justify={"end"}>
                      <Typography color={"primary"}> {format(item.rate, 3)}</Typography>
                    </FlexboxGrid.Cell>
                    <FlexboxGrid.Cell colspan={1} justify={"end"}>
                      <Typography color={"primary"}> {format(item.apr, 3)} </Typography>
                    </FlexboxGrid.Cell>
                    <FlexboxGrid.Cell colspan={1} justify={"end"}>
                      <Typography color={"primary"}>
                        {format((item.price), 3)}
                      </Typography>
                    </FlexboxGrid.Cell>
                    <FlexboxGrid.Cell colspan={2} justify={"end"}>
                      {item.status === "Expired"
                        ? <Badge color={"gray"} content={item.status}/>
                        : <Badge color={"green"} content={item.status}/>
                      }
                    </FlexboxGrid.Cell>
                    <FlexboxGrid.Cell colspan={2} justify={"end"}>
                      <Typography color={"primary"}>
                        $ {countPrice(item.price, loanEstimateOffer.loanAmount)}
                      </Typography>
                    </FlexboxGrid.Cell>
                    <FlexboxGrid.Cell colspan={2} justify={"end"}>
                      <Typography
                        color={"primary"}> {DateTime.fromISO(item.lastUpdate).toFormat("hh:mm:ss a")}</Typography>
                    </FlexboxGrid.Cell>
                    <FlexboxGrid.Cell colspan={2}>
                      <Button appearance={"text"} disabled={isSelected}
                              onClick={() => onSelect(item, index)}>SELECT</Button>
                    </FlexboxGrid.Cell>
                  </FlexboxGrid>
                </List.Item>
              );
            })}
            {
              popperActiveIndex &&
              <Popper alignment={Alignment.Bottom} open={true} anchorBounding={popperAnchorBounding}>
                <Box>
                  <Table data={filteredRates[ popperActiveIndex ].adjustments} bordered width={600} cellBordered
                         wordWrap="break-word"
                         style={{ border: "1px solid var(--rc-background-tint5)", borderRadius: "5px" }} minHeight={20}
                         autoHeight>
                    {
                      Object.keys(filteredRates[ popperActiveIndex ].adjustments[ 0 ]).map((k) => {
                        return <Column flexGrow={1}>
                          <HeaderCell>{k}</HeaderCell>
                          <Cell dataKey={k}/>
                        </Column>;
                      })
                    }
                  </Table>
                </Box>
              </Popper>
            }
          </List>
        </> : <EmptyState
          background
          icon={"cached"}
          title={"No active rates"}
          subtitle={"All rates are expired at this time. Click on “Show expired rates” to see the results."}/>}
  </Container>;
});
